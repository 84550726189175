import React from "react";
import { Text } from "flicket-ui";

export const Badge = ({
  children,
  color = "#ce202f",
}: {
  children: React.ReactNode;
  color?: string;
}) => (
  <Text
    backgroundColor={color as any}
    style={{
      padding: "4px 8px",
      borderRadius: 3,
      textTransform: "uppercase",
      fontSize: 11,
      fontWeight: 800,
      fontStyle: "italic",
      verticalAlign: "text-bottom",
    }}
    fontSize={1}
    display="inline-block"
    whiteSpace="nowrap"
    lineHeight="normal"
    color="white"
    mr={1}
  >
    {children}
  </Text>
);
